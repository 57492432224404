import { makeStyles, Typography, Popover, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ArrowDropDown, Event } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';

import { iRootState, InsightDispatch } from '../../redux/store';
import DateRangeSelectorPopoverContent from './DateRangeSelectorPopoverContent';

const useStyles = makeStyles(theme => ({
  date: {
    padding: '0',
    margin: 'auto 0 auto 0',
    cursor: 'pointer'
  },
  dateArrow: {
    marginBottom: '-5px'
  },
  event: {
    margin: `auto 0 auto ${theme.spacing(3)}px`
  },
  middle: {
    marginTop: 'auto',
    marginBottom: 'auto'
  }
}));

const DateRangeSelector = function DateRangeSelector(): ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch<InsightDispatch>();
  const startDate = useSelector((state: iRootState) => state.filters.startDate);
  const endDate = useSelector((state: iRootState) => state.filters.endDate);
  const isPopoverOpen = useSelector(
    (state: iRootState) => state.filters.isDatePopoverOpen || false
  );
  const anchorElement = useSelector(
    (state: iRootState) => state.filters.anchorElement
  );

  const handleClick = function handleClick(
    event: React.MouseEvent<Element>
  ): void {
    if (event && event.currentTarget) {
      dispatch.filters.toggleDatePopoverOpen(event.currentTarget);
    }
  };
  const handleClose = function handleClose(): void {
    dispatch.filters.toggleDatePopoverOpen();
  };

  return (
    <>
      <Grid item>
        <Event className={classes.event} color='inherit' />
      </Grid>
      <Grid item>
        <Typography className={classes.date} onClick={handleClick}>
          {`${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`}
          <ArrowDropDown className={classes.dateArrow} />
        </Typography>
      </Grid>
      <Grid item>
        <Popover
          open={isPopoverOpen}
          anchorEl={anchorElement}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <DateRangeSelectorPopoverContent />
        </Popover>
      </Grid>
    </>
  );
};

export default DateRangeSelector;
