import { createModel } from '@rematch/core';

type FlyoutState = {
  open: boolean;
};

const toggleOpen = function handleToggleOpen(state: FlyoutState): FlyoutState {
  return {
    open: !state.open
  };
};

const flyout = createModel({
  state: {
    open: false
  },
  reducers: {
    toggleOpen
  }
});

export default flyout;
