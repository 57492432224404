import Axios, { AxiosInstance } from 'axios';

const createDataAccessAxiosInstance = function createDataAccessAxiosInstance(
  token: string
): AxiosInstance {
  if (typeof token !== 'string') {
    throw new Error('Token must be a string');
  }

  const axiosApiInstance = Axios.create({
    baseURL: window.AutoQuotes.config.insightApiUrl,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return axiosApiInstance;
};

export default createDataAccessAxiosInstance;
