import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import DateRangeSelector from '../../Filters/DateRangeSelector/DateRangeSelector';
import FilterBarCompanySelector from './FilterBarCompanySelector';
import FilterBarResetDashboard from './FilterBarResetDashboard';

const useClasses = makeStyles(theme => ({
  filterBar: {
    backgroundColor: '#82a4c3',
    color: theme.palette.common.white,
    flex: `0 0 ${theme.spacing(5)}px`
  },
  rightFilters: {
    flexGrow: 1
  }
}));

const FilterBar = function FilterBar(): React.ReactElement {
  const classes = useClasses();

  return (
    <Grid
      container
      className={classes.filterBar}
      alignItems='center'
      spacing={1}
    >
      <DateRangeSelector />
      <FilterBarCompanySelector />
      <div className={classes.rightFilters} />
      <FilterBarResetDashboard />
    </Grid>
  );
};

export default FilterBar;
