import React from 'react';
import { Hidden } from '@material-ui/core';
import CompanySelector from '../../Filters/CompanySelector/CompanySelector';

const FlyoutCompanySelector = function FlyoutCompanySelector(): React.ReactElement {
  return (
    <Hidden mdUp>
      <CompanySelector maxWidth={170} />
    </Hidden>
  );
};

export default FlyoutCompanySelector;
