import { createMuiTheme } from '@material-ui/core';

const AqInsightTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#005f9e'
    },
    secondary: {
      main: '#7f8080'
    }
  },
  overrides: {
    MuiAlert: {
      message: {
        flexGrow: 1
      }
    },
    MuiSelect: {
      icon: {
        color: '#fff'
      }
    }
  }
});

export default AqInsightTheme;
