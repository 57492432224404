import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  List,
  Checkbox,
  AccordionDetails,
  Grid,
  Button,
  makeStyles,
  ListItemIcon,
  Typography,
  FormControlLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import LegalTextMessage from './LegalTextMessage';
import { InsightDispatch, iRootState } from '../../redux/store';

type ShareItem = {
  id: string;
  text: string;
  preselected: boolean;
};

type ShareAction = {
  text: string;
  callback: (ids: string[]) => void;
};

type ShareSectionListProps = {
  items: ShareItem[];
  actions: ShareAction[];
  sectionName: string;
};

const useClasses = makeStyles(theme => ({
  checkbox: {
    margin: 0,
    padding: 0
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginRight: 5
  },
  item: {
    margin: 0,
    padding: 0
  },
  consent: {
    marginRight: 200,
    alignContent: 'center',
    marginLeft: 200
  },
  legal: {
    marginRight: 50,
    marginLeft: 50
  },
  linkButton: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline',
    margin: 0,
    padding: 0,
    textTransform: 'capitalize',
    color: 'blue'
  }
}));

const ShareSectionListLegal = function ShareSectionListLegal({
  items,
  actions,
  sectionName
}: ShareSectionListProps): React.ReactElement {
  const classes = useClasses();
  const dispatch = useDispatch<InsightDispatch>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const messageEndRef = React.useRef<HTMLInputElement>(null);
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end'
    });
  };

  const legalTextDisplayed = useSelector(
    (state: iRootState) => state.shareDataManufacturers.legalTextDisplayed
  );
  const consentAccepted = useSelector(
    (state: iRootState) => state.shareDataManufacturers.consentAccepted
  );
  const isMfrChecked = useSelector(
    (state: iRootState) => state.shareDataManufacturers.isMfrChecked
  );

  const onChecked = function onChecked(id: string): void {
    const newSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter(i => i !== id)
      : [...selectedIds, id];
    setSelectedIds(newSelectedIds);
    dispatch.shareDataManufacturers.setIsMfrChecked(true);
    setTimeout(scrollToBottom, 500);
  };

  const onCheckedConsent = function onCheckedConsent(): void {
    dispatch.shareDataManufacturers.toggleConsentAcceptance();
  };

  const justify = sectionName === 'approve' ? 'flex-end' : 'center';
  const consentShow = sectionName === 'share';
  const showLegalText = function showLegalText(): void {
    dispatch.shareDataManufacturers.setLegalTextDisplay(true);
  };

  const legalTextShow = consentShow && (isMfrChecked || legalTextDisplayed);

  const consentText1 = 'I confirm I have read the ';
  const consentText2 =
    ' and I consent to sharing the AQ Insight Data with the manufacturer.';
  const consentTextSection = (
    <>
      <Typography>
        {consentText1}
        <Button className={classes.linkButton} onClick={showLegalText}>
          Terms and Conditions
        </Button>
        {consentText2}
      </Typography>
    </>
  );

  const consentSection = (
    <Checkbox
      onChange={(): void => onCheckedConsent()}
      checked={consentAccepted}
    />
  );

  return (
    <AccordionDetails>
      <Grid ref={messageEndRef} container direction='column'>
        <Grid item className={classes.item}>
          <List dense>
            {items.map(i => (
              <ListItem key={i.id} className={classes.item}>
                <ListItemIcon>
                  <Checkbox
                    disabled={i.preselected}
                    onChange={(): void => onChecked(i.id)}
                    checked={i.preselected || selectedIds.includes(i.id)}
                    className={classes.checkbox}
                  />
                </ListItemIcon>
                <ListItemText>{i.text}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
        {consentShow && (
          <>
            <FormControlLabel
              className={classes.consent}
              labelPlacement='end'
              control={consentSection}
              label={consentTextSection}
            />
          </>
        )}
        <Grid container justify={justify}>
          {actions.map(a => (
            <Button
              size='small'
              disabled={selectedIds.length === 0 || !consentAccepted}
              key={a.text}
              color='inherit'
              variant='outlined'
              onClick={(): void => {
                a.callback(selectedIds);
                setSelectedIds([]);
              }}
              className={classes.actionButton}
            >
              {a.text}
            </Button>
          ))}
          {legalTextShow && <LegalTextMessage />}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
};

export default ShareSectionListLegal;
