import {
  Card,
  CardHeader,
  Collapse,
  CardContent,
  Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import ExpandNotification from './ExpandNotification';
import { iRootState } from '../../redux/store';

type Notification = {
  key: string;
  title: string;
  message: string;
  read: boolean;
  startDate: Date;
};

type NotificationMessageProps = {
  notification: Notification;
};

const NotificationMessage = function NotificationMessage({
  notification
}: NotificationMessageProps): React.ReactElement {
  const { title, message, startDate, key } = notification;
  const displayDate = format(startDate, 'MM/dd/yyyy');
  const action = <ExpandNotification notificationKey={key} />;
  const expandedNotification = useSelector(
    (state: iRootState) => state.notificationCenter.expandedNotification
  );

  return (
    <Card>
      <CardHeader title={title} subheader={displayDate} action={action} />
      <Collapse in={expandedNotification === key}>
        <CardContent>
          <Typography>
            {/*  eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default NotificationMessage;
