import React from 'react';
import { IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { InsightDispatch, iRootState } from '../../redux/store';

type ExpandNotificationProps = {
  notificationKey: string;
};

const ExpandNotification = function ExpandNotification({
  notificationKey
}: ExpandNotificationProps): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();
  const expandedNotification = useSelector(
    (state: iRootState) => state.notificationCenter.expandedNotification
  );
  const icon =
    expandedNotification === notificationKey ? (
      <KeyboardArrowUp />
    ) : (
      <KeyboardArrowDown />
    );
  const onClick = function handleOnClose(): void {
    const keyToExpand =
      notificationKey !== expandedNotification ? notificationKey : '';

    dispatch.notificationCenter.expandNotification(keyToExpand);
    dispatch.notificationCenter.saveReadNotification(notificationKey);
  };

  return <IconButton onClick={onClick}>{icon}</IconButton>;
};

export default ExpandNotification;
