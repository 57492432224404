import {
  Checkbox,
  Grid,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  InputAdornment
} from '@material-ui/core';
import { Business } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { industryRegions, placeholderVendor } from '../../Constants';
import { LicenseType } from '../../redux/models/license';
import { InsightDispatch, iRootState } from '../../redux/store';
import { DashboardType } from '../../redux/models/dashboards';

type CompanySelectorProps = {
  maxWidth?: number;
};

const useStyles = makeStyles(theme => ({
  companySelector: {
    color: '#fff',
    fontSize: theme.typography.fontSize,
    maxWidth: (props: CompanySelectorProps): number | undefined =>
      props.maxWidth
  }
}));

const canSelectCompany = function canSelectCompany(
  selectableCompanies: string[],
  licenseType: LicenseType
): boolean {
  return selectableCompanies.length > 1 && licenseType === LicenseType.Pro;
};

const canOnlyViewOneRep = function canOnlyViewOneRep(
  selectableCompanies: string[],
  licenseType: LicenseType,
  dashboardType: DashboardType
): boolean {
  return (
    selectableCompanies.length === 1 &&
    licenseType === LicenseType.Pro &&
    dashboardType === DashboardType.MfrRep
  );
};

const CompanySelector = function CompanySelector(
  props: CompanySelectorProps
): React.ReactElement | null {
  const dashboardType = useSelector(
    (state: iRootState) => state.dashboards.dashboardType
  );

  const DashboardLabel =
    dashboardType === DashboardType.Mfr ? DashboardType.Mfr : DashboardType.Rep;
  const allAvailableCompanies = useSelector((state: iRootState) =>
    dashboardType === DashboardType.Mfr
      ? state.filters.manufacturers
      : state.filters.reps
  );

  const selectedCompanies = useSelector((state: iRootState) =>
    dashboardType === DashboardType.Mfr
      ? state.filters.selectedManufacturers
      : state.filters.selectedReps
  );

  const licenseType = useSelector((state: iRootState) => state.license.type);
  const classes = useStyles(props);

  const dispatch = useDispatch<InsightDispatch>();
  useEffect(() => {
    dispatch.filters.getAvailableCompanies(dashboardType);
  }, [dashboardType, dispatch]);

  const onChange = function handleOnChange(
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void {
    dispatch.filters.setSelectedCompanies(
      event.target.value as string[],
      dashboardType
    );
  };

  const selectableCompanies = allAvailableCompanies.filter(
    mfr => !industryRegions.includes(mfr) && mfr !== placeholderVendor
  );

  if (canOnlyViewOneRep(selectableCompanies, licenseType, dashboardType)) {
    return <Typography>{selectableCompanies[0]}</Typography>;
  }

  if (!canSelectCompany(selectableCompanies, licenseType)) {
    return null;
  }

  const numberOfSelectedCompaniesToDisplay = 2;

  const renderValue = function renderValue(selected: unknown): string {
    return (selected as string[])
      .slice(0, numberOfSelectedCompaniesToDisplay)
      .join(', ');
  };

  const isCompanySelected = function isCompanySelected(
    company: string
  ): boolean {
    return selectedCompanies.some(mfr => mfr === company);
  };

  const shouldShowHelperText =
    selectedCompanies.length > numberOfSelectedCompaniesToDisplay;
  const numberOfOverflowSelectedCompanies =
    selectedCompanies.length - numberOfSelectedCompaniesToDisplay;

  const startAdornment = (
    <InputAdornment position='start'>
      <Business />
    </InputAdornment>
  );

  return (
    <>
      <Grid item>
        <Select
          className={classes.companySelector}
          label={DashboardLabel}
          value={selectedCompanies}
          onChange={onChange}
          displayEmpty
          disableUnderline
          multiple
          renderValue={renderValue}
          startAdornment={startAdornment}
        >
          {selectableCompanies.map(company => (
            <MenuItem value={company} key={company}>
              <Checkbox checked={isCompanySelected(company)} />
              <ListItemText primary={company} />
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {shouldShowHelperText && (
        <Grid item>
          <Typography variant='subtitle2'>{`+ ${numberOfOverflowSelectedCompanies} More`}</Typography>
        </Grid>
      )}
    </>
  );
};

export default CompanySelector;
