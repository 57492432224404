import { Grid, Typography, Container, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useAuth0, Auth0User } from './Auth0Provider';
import { CompanyRegionClaim } from './Constants';

const useClasses = makeStyles(() => ({
  container: {
    marginTop: '200px'
  }
}));

const getSupportEmail = function getSupportEmail(user?: Auth0User): string {
  const defaultSupportEmail = 'support@aq-fes.com';
  if (!user) {
    return defaultSupportEmail;
  }

  const region = user[CompanyRegionClaim];
  if (region === 'CQR') {
    return 'support.uk@aq-fes.com';
  }

  return defaultSupportEmail;
};

const NotAuthorized = function NotAuthorized(): ReactElement {
  const classes = useClasses();

  const { user } = useAuth0();
  const supportEmail = getSupportEmail(user);

  const supportMessage =
    "You don't have access to AQ Insight. If you think this is a mistake, please contact us at ";

  return (
    <Container maxWidth='sm' className={classes.container}>
      <Grid container justify='center'>
        <Typography variant='h5' color='secondary'>
          {supportMessage}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        </Typography>
      </Grid>
    </Container>
  );
};

export default NotAuthorized;
