import { Hidden, Typography } from '@material-ui/core';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import CloseButton from './CloseButton';

const UpgradeAQInsightProHeader = function UpgradeAQInsightProHeader(): React.ReactElement {
  const proHeader = `Upgrade to Insight Pro`;
  return (
    <Grid container direction='row'>
      <Hidden smUp>
        <CloseButton />
      </Hidden>
      <Grid item>
        <Typography color='primary' variant='h5'>
          {proHeader}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UpgradeAQInsightProHeader;
