/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter } from 'react-router-dom';
import { ai } from './AppInsightsTelemetryService';

class TelemetryProvider extends Component {
  constructor(props) {
    super();
    this.state = { ...props, initialized: false };
  }

  componentDidMount() {
    const { history } = this.props;
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey =
      '8ebc58b9-7e59-42db-a06a-3fb978110c14';
    if (
      !initialized &&
      Boolean(AppInsightsInstrumentationKey) &&
      Boolean(history)
    ) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      this.setState({ initialized: true });
    }
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
