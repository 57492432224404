import { Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { useAuth0 } from '../Auth0Provider';
import { CompanyNameClaim } from '../Constants';

const CompanyInfo = function CompanyInfo(): React.ReactElement | null {
  const { user } = useAuth0();
  if (!user) {
    return null;
  }

  const company = user[CompanyNameClaim];
  const companyDisplayName = `|  ${company}`;
  return (
    <Hidden smDown>
      <Typography>{companyDisplayName}</Typography>
    </Hidden>
  );
};

export default CompanyInfo;
