import { createModel } from '@rematch/core';

export enum DashboardType {
  Mfr = 'Manufacturer',
  Rep = 'Rep',
  MfrRep = 'ManufacturerRep'
}

type DashboardIds = {
  freeDashboardId: string;
  proDashboardId: string;
  repDashboardId: string;
  mfrRepDashboardId: string;
};

type DashboardState = {
  dashboardType: DashboardType;
  dashboards: DashboardIds;
};

const updateDashboardType = function handleUpdateDashboardType(
  state: DashboardState,
  dashboardType: DashboardType
): DashboardState {
  return {
    ...state,
    dashboardType
  };
};
const setDashboardIds = function setDashboardIds(
  state: DashboardState,
  dashboards: DashboardIds
): DashboardState {
  return {
    ...state,
    dashboards
  };
};

const dashboards = createModel({
  state: {
    dashboardType: DashboardType.Mfr,
    dashboards: {
      freeDashboardId: '',
      proDashboardId: '',
      repDashboardId: '',
      mfrRepDashboardId: ''
    }
  },
  reducers: {
    updateDashboardType,
    setDashboardIds
  }
});

export default dashboards;
