import { createModel } from '@rematch/core';
import Axios from 'axios';
import { isAfter, isBefore, subMonths } from 'date-fns';
import { industryRegions, placeholderVendor } from '../../Constants';
import { DashboardType } from './dashboards';

export type FilterState = {
  startDate: Date;
  endDate: Date;
  isDatePopoverOpen: boolean;
  anchorElement: Element | null;
  manufacturers: string[];
  selectedManufacturers: string[];
  reps: string[];
  selectedReps: string[];
  filterKey: number;
};

type FilterRootState = {
  filters: FilterState;
};

const setStartDate = function handleSetStartDate(
  state: FilterState,
  startDate: Date
): FilterState {
  if (isAfter(startDate, state.endDate)) {
    return state;
  }

  return {
    ...state,
    startDate,
    endDate: state.endDate
  };
};

const setEndDate = function handleSetEndDate(
  state: FilterState,
  endDate: Date
): FilterState {
  if (isBefore(endDate, state.startDate)) {
    return state;
  }

  return {
    ...state,
    endDate,
    startDate: state.startDate
  };
};

const toggleDatePopoverOpen = function toggleDatePopoverOpen(
  state: FilterState,
  element?: Element | null
): FilterState {
  return {
    ...state,
    isDatePopoverOpen: !state.isDatePopoverOpen,
    anchorElement: element || null
  };
};

const setCompaniesOnState = function setCompaniesOnState(
  state: FilterState,
  companies: string[],
  propertyName: string
): FilterState {
  return {
    ...state,
    [propertyName]: companies
  };
};

const setCompanies = function handleSetCompanies(
  state: FilterState,
  companies: string[],
  dashboardType: DashboardType
): FilterState {
  if (
    dashboardType === DashboardType.Rep ||
    dashboardType === DashboardType.MfrRep
  ) {
    return setCompaniesOnState(state, companies, 'reps');
  }

  if (dashboardType === DashboardType.Mfr) {
    return setCompaniesOnState(state, companies, 'manufacturers');
  }

  return state;
};

const setSelectedCompanies = function handleSetSelectedManufacturers(
  state: FilterState,
  selectedCompanies: string[],
  dashboardType: DashboardType
): FilterState {
  if (
    dashboardType === DashboardType.Rep ||
    dashboardType === DashboardType.MfrRep
  ) {
    return setCompaniesOnState(state, selectedCompanies, 'selectedReps');
  }

  if (dashboardType === DashboardType.Mfr) {
    return setCompaniesOnState(
      state,
      selectedCompanies,
      'selectedManufacturers'
    );
  }

  return state;
};

const getFirstSelectableCompany = function getSelectedCompaniesFromAvailableCompanies(
  companies: string[]
): string[] {
  if (companies.length === 0) {
    return [];
  }

  const nonIndustryCompany = companies.find(
    mfr => !industryRegions.includes(mfr) && mfr !== placeholderVendor
  );

  if (!nonIndustryCompany) {
    return [placeholderVendor];
  }

  return [nonIndustryCompany];
};

const resetCompanies = function handleResetCompanies(
  state: FilterState
): FilterState {
  return {
    ...state,
    selectedReps: getFirstSelectableCompany(state.reps),
    selectedManufacturers: getFirstSelectableCompany(state.manufacturers)
  };
};

const resetDashboard = function handleResetDashboard(
  state: FilterState
): FilterState {
  return {
    ...state,
    filterKey: state.filterKey + 1
  };
};

const getDimensionFromDashboardType = function getDimensionFromDashboardType(
  dashboardType: DashboardType
): string {
  if (dashboardType === DashboardType.Mfr) {
    return '[DIM_ALL_PRODUCTS.VENDOR_NAME]';
  }

  if (
    dashboardType === DashboardType.Rep ||
    dashboardType === DashboardType.MfrRep
  ) {
    return '[DIM_COMPANY_REP.COMPANY_NAME]';
  }

  return '';
};

const manufacturerIsAlreadyLoaded = function manufacturerIsAlreadyLoaded(
  rootState: FilterRootState,
  dashboardType: DashboardType
): boolean {
  return (
    rootState.filters.manufacturers.length > 0 &&
    dashboardType === DashboardType.Mfr
  );
};

const repIsAlreadyLoaded = function repIsAlreadyLoaded(
  rootState: FilterRootState,
  dashboardType: DashboardType
): boolean {
  return (
    rootState.filters.reps.length > 0 &&
    (dashboardType === DashboardType.Rep ||
      dashboardType === DashboardType.MfrRep)
  );
};

const loadingAlreadyLoadedCompany = function loadingAlreadyLoadedCompany(
  rootState: FilterRootState,
  dashboardType: DashboardType
): boolean {
  return (
    manufacturerIsAlreadyLoaded(rootState, dashboardType) ||
    repIsAlreadyLoaded(rootState, dashboardType)
  );
};

const filters = createModel({
  state: {
    startDate: subMonths(new Date(), 6),
    endDate: new Date(),
    isDatePopoverOpen: false,
    anchorElement: null,
    reps: [] as string[],
    selectedReps: [] as string[],
    manufacturers: [] as string[],
    selectedManufacturers: [] as string[],
    filterKey: 1
  },
  reducers: {
    setStartDate,
    setEndDate,
    toggleDatePopoverOpen,
    setCompanies,
    setSelectedCompanies,
    resetCompanies,
    resetDashboard
  },
  effects: {
    async getAvailableCompanies(
      dashboardType: DashboardType,
      rootState: FilterRootState
    ): Promise<void> {
      if (loadingAlreadyLoadedCompany(rootState, dashboardType)) {
        return;
      }

      const sisenseApi = Axios.create({
        baseURL: `${global.AutoQuotes.config.sisenseUrl}/api`
      });

      const { datasource } = global.AutoQuotes.config;
      const jaqlUrl = `datasources/${datasource}/jaql`;
      const dimension = getDimensionFromDashboardType(dashboardType);
      const jaqlPostBody = {
        datasource: {
          title: datasource,
          fullname: `Set/${datasource}`
        },
        metadata: [
          {
            dim: dimension,
            sort: 'asc'
          }
        ],
        offset: 0,
        count: 50
      };

      const jaqlConfig = { withCredentials: true };

      try {
        const jaqlValuesForCompanyName = await sisenseApi.post(
          jaqlUrl,
          jaqlPostBody,
          jaqlConfig
        );

        if (jaqlValuesForCompanyName.data.error) {
          return;
        }

        const companies = jaqlValuesForCompanyName.data.values.flat();
        this.setCompanies(companies, dashboardType);
        const selectedCompanies = getFirstSelectableCompany(companies);
        this.setSelectedCompanies(selectedCompanies, dashboardType);
      } catch (error) {
        this.setSelectedCompanies([]);
      }
    }
  }
});

export default filters;
