export const CompanyNameClaim = 'https://aqnet.com/companyname';
export const CompanyRegionClaim = 'https://aqnet.com/companyregion';
export const industryRegions = ['US Industry', 'UK Industry', 'CA Industry'];
export const placeholderVendor = '1 PLACEHOLDER MFR';
export const trialPeriodEndDate = new Date('07/01/2020');
export const AQInsightProLicenseClaim = 'AQView_Premium';
export const AQInsightRepLicenseClaim = 'AQView_Rep';
export const AQInsightRepAdmin = 'AQDataAccess_RepAdmin';
export const AQInsightMfr = 'AQDataAccess_Mfr';
export const showRepNotificationKey = 'showRepNotification';
export const repNotificationStartDate = new Date('09/29/2020');
export const sharedRepStorageKey = 'sharedReps';
export const approvedMfrStorageKey = 'approvedMfrs';

export const AmplitudeEventKeys = {
  ShareButtonClicked: 'Share - Share Button Clicked',
  ShareMyDataButtonClicked: 'Share - Share My Data Button Clicked',
  RevokeButtonClicked: 'Share - Revoke Button Clicked',
  ApproveButtonClicked: 'Share - Approve Button Clicked',
  DenyButtonClicked: 'Share - Deny Button Clicked',
  StopReceivingButtonClicked: 'Share - Stop Receiving Button Clicked',
  RequestButtonClicked: 'Share - Request Data Button Clicked'
};
