import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  List,
  Checkbox,
  AccordionDetails,
  Grid,
  Button,
  makeStyles,
  ListItemIcon
} from '@material-ui/core';

type ShareItem = {
  id: string;
  text: string;
  preselected: boolean;
};

type ShareAction = {
  text: string;
  callback: (ids: string[]) => void;
};

type ShareSectionListProps = {
  items: ShareItem[];
  actions: ShareAction[];
};

const useClasses = makeStyles(theme => ({
  checkbox: {
    margin: 0,
    padding: 0
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginRight: 5
  },
  item: {
    margin: 0,
    padding: 0
  }
}));

const ShareSectionList = function ShareSectionList({
  items,
  actions
}: ShareSectionListProps): React.ReactElement {
  const classes = useClasses();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onChecked = function onChecked(id: string): void {
    const newSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter(i => i !== id)
      : [...selectedIds, id];
    setSelectedIds(newSelectedIds);
  };

  return (
    <AccordionDetails>
      <Grid container direction='column'>
        <Grid item className={classes.item}>
          <List dense>
            {items.map(i => (
              <ListItem key={i.id} className={classes.item}>
                <ListItemIcon>
                  <Checkbox
                    disabled={i.preselected}
                    onChange={(): void => onChecked(i.id)}
                    checked={i.preselected || selectedIds.includes(i.id)}
                    className={classes.checkbox}
                  />
                </ListItemIcon>
                <ListItemText>{i.text}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid container justify='flex-end'>
          {actions.map(a => (
            <Button
              size='small'
              disabled={selectedIds.length === 0}
              key={a.text}
              color='inherit'
              variant='outlined'
              onClick={(): void => {
                a.callback(selectedIds);
                setSelectedIds([]);
              }}
              className={classes.actionButton}
            >
              {a.text}
            </Button>
          ))}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
};

export default ShareSectionList;
