import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from '@material-ui/core';
import { useAuth0 } from '../../Auth0Provider';
import { InsightDispatch, iRootState } from '../../redux/store';
import ShareSectionHeader from './ShareSectionHeader';
import ShareSectionList from './ShareSectionList';
import ShareSectionListLegal from './ShareSectionListLegal';
import { approvedMfrStorageKey, AmplitudeEventKeys } from '../../Constants';
import { ManufacturerItem } from '../../redux/models/shareDataManufacturers';
import RepMfrDataAccessStatus from '../../RepMfrDataAccessStatuses';
import trackAmplitudeEvent from './trackAmplitudeEvents';

const MfrsRequestDataSelector = function MfrsRequestDataSelector(): React.ReactElement | null {
  const companyId = useSelector(
    (state: iRootState) => state.companyInfo.companyId
  );

  const allAvailableManufacturers = useSelector(
    (state: iRootState) => state.shareDataManufacturers.manufacturers
  );

  const isMfrChecked = useSelector(
    (state: iRootState) => state.shareDataManufacturers.isMfrChecked
  );

  const messageEndRef = React.useRef<HTMLInputElement>(null);
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end'
    });
  };

  if (isMfrChecked) {
    setTimeout(scrollToBottom, 500);
  }

  const { dataAccessToken } = useAuth0();

  const dispatch = useDispatch<InsightDispatch>();

  const getApprovedMfrs = function getApprovedMfrs(): ManufacturerItem[] {
    const previousApprovedMfrs = localStorage.getItem(approvedMfrStorageKey);
    if (previousApprovedMfrs) {
      return JSON.parse(previousApprovedMfrs);
    }
    return [];
  };

  const getMfrs = useCallback(() => {
    dispatch.shareDataManufacturers.getAvailableRepManufacturers({
      companyId,
      dataAccessToken
    });
  }, [companyId, dataAccessToken, dispatch]);

  useEffect(() => {
    getMfrs();
  }, [getMfrs]);

  const mapMfrsByStatus = function mapMfrsByStatus(
    statuses: (string | null)[]
  ): { id: string; text: string; preselected: boolean }[] {
    return allAvailableManufacturers
      .filter(r => statuses.includes(r.status))
      .map(m => ({
        id: m.manufacturerId,
        text: m.manufacturerName,
        preselected: false
      }));
  };

  const oldApprovedMfrs = getApprovedMfrs();
  const selectableManufacturers = mapMfrsByStatus([
    '',
    null,
    RepMfrDataAccessStatus.RevokedByRep,
    RepMfrDataAccessStatus.RevokedByMfr,
    RepMfrDataAccessStatus.Rejected
  ]);

  const pendingManufacturers = mapMfrsByStatus([
    RepMfrDataAccessStatus.Pending
  ]);

  const getApprovedMfrText = function getApprovedMfrText(
    mfrId: string,
    mfrName: string
  ): string {
    const manufacturerHasNotBeenSeen = !oldApprovedMfrs.some(
      r => r.manufacturerId === mfrId
    );
    const suffix = manufacturerHasNotBeenSeen ? ' - 1 New!' : '';
    return `${mfrName}${suffix}`;
  };

  const approvedManufacturers = mapMfrsByStatus([
    RepMfrDataAccessStatus.Approved
  ]).map(r => ({
    ...r,
    text: getApprovedMfrText(r.id, r.text)
  }));

  const newApprovedMfrs = approvedManufacturers.filter(
    value => !oldApprovedMfrs.map(a => a.manufacturerId).includes(value.id)
  );

  const updateStatusData = function updateStatusData(
    status: string,
    event: string
  ): (ids: string[]) => Promise<void> {
    return async (ids: string[]): Promise<void> => {
      const mfrStatusList = ids.map(id => ({
        manufacturerId: id,
        status
      }));

      await dispatch.shareDataManufacturers.shareData({
        mfrStatusList,
        dataAccessToken
      });

      dispatch.shareDataManufacturers.checkConsentAcceptance(false);
      dispatch.shareDataManufacturers.setIsMfrChecked(false);

      trackAmplitudeEvent(ids, event, dispatch);
      await getMfrs();
    };
  };

  const approvedMfrsActions = [
    {
      text: 'Revoke',
      callback: updateStatusData(
        RepMfrDataAccessStatus.RevokedByRep,
        AmplitudeEventKeys.RevokeButtonClicked
      )
    }
  ];

  const pendingMfrsActions = [
    {
      text: 'Approve',
      callback: updateStatusData(
        RepMfrDataAccessStatus.Approved,
        AmplitudeEventKeys.ApproveButtonClicked
      )
    },
    {
      text: 'Deny',
      callback: updateStatusData(
        RepMfrDataAccessStatus.Rejected,
        AmplitudeEventKeys.DenyButtonClicked
      )
    }
  ];

  const selectableMfrsActions = [
    {
      text: 'Share My Data',
      callback: updateStatusData(
        RepMfrDataAccessStatus.Approved,
        AmplitudeEventKeys.ShareMyDataButtonClicked
      )
    }
  ];

  const approvedHeaderText = `You are Sharing Your Data (${approvedManufacturers.length}) - ${newApprovedMfrs.length} New!`;
  const pendingHeaderText = `Pending Your Approval (${pendingManufacturers.length})`;

  const saveSharedMfrs = function saveSharedMfrs(
    approvedRepsArg: ManufacturerItem[]
  ): void {
    const sharedMfrs = JSON.stringify([...approvedRepsArg]);
    localStorage.setItem(approvedMfrStorageKey, sharedMfrs);
  };

  saveSharedMfrs(
    approvedManufacturers.map(r => ({
      manufacturerId: r.id,
      manufacturerName: r.text,
      status: RepMfrDataAccessStatus.Approved
    }))
  );

  return (
    <>
      <div ref={messageEndRef}>
        <Accordion defaultExpanded style={{ padding: 0, margin: 0 }}>
          <ShareSectionHeader label={approvedHeaderText} />
          <ShareSectionList
            items={approvedManufacturers}
            actions={approvedMfrsActions}
          />
        </Accordion>

        <Accordion defaultExpanded style={{ padding: 0, margin: 0 }}>
          <ShareSectionHeader label={pendingHeaderText} />
          <ShareSectionListLegal
            sectionName='approve'
            items={pendingManufacturers}
            actions={pendingMfrsActions}
          />
        </Accordion>

        <Accordion defaultExpanded style={{ padding: 0, margin: 0 }}>
          <ShareSectionHeader label='Share My Data' />
          <ShareSectionListLegal
            sectionName='share'
            items={selectableManufacturers}
            actions={selectableMfrsActions}
          />
        </Accordion>
      </div>
    </>
  );
};

export default MfrsRequestDataSelector;
