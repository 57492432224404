import { createModel } from '@rematch/core';

export enum LicenseType {
  Free = 'Free',
  Pro = 'Pro',
  Rep = 'Rep'
}

type LicenseState = {
  type: LicenseType;
};

const updateType = function handleUpdateType(
  state: LicenseState,
  type: LicenseType
): LicenseState {
  return {
    type
  };
};

const license = createModel({
  state: { type: LicenseType.Free },
  reducers: {
    updateType
  }
});

export default license;
