import React from 'react';
import { Notifications } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, IconButton } from '@material-ui/core';
import { isAfter } from 'date-fns';
import { InsightDispatch, iRootState } from '../../redux/store';

const NotificationIcon = function NotificationIcon(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();
  const notifications = useSelector(
    (state: iRootState) => state.notificationCenter.notifications
  );
  const unreadNotifications = notifications.filter(n => !n.read);
  let notificationToExpand = '';
  let latestDate = new Date('01/01/2020');
  notifications.forEach(n => {
    if (isAfter(n.startDate, latestDate)) {
      latestDate = n.startDate;
      notificationToExpand = n.key;
    }
  });
  const onClick = function handleOnClick(): void {
    dispatch.notificationCenter.toggleOpen();
    dispatch.notificationCenter.expandNotification(notificationToExpand);
    dispatch.notificationCenter.saveReadNotification(notificationToExpand);
  };

  return (
    <IconButton color='inherit' onClick={onClick}>
      <Badge badgeContent={unreadNotifications.length} color='secondary'>
        <Notifications />
      </Badge>
    </IconButton>
  );
};

export default NotificationIcon;
