import React from 'react';
import { Hidden } from '@material-ui/core';
import CompanySelector from '../../Filters/CompanySelector/CompanySelector';

const FilterBarCompanySelector = function FilterBarCompanySelector(): React.ReactElement {
  return (
    <Hidden smDown>
      <CompanySelector />
    </Hidden>
  );
};

export default FilterBarCompanySelector;
