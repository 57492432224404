import { Link } from '@material-ui/core';
import React from 'react';

const WhyUpgradeLink = function WhyUpgradeLink(): React.ReactElement {
  return (
    <Link
      href='https://aq-fes.com/product/aq-insight/'
      variant='body2'
      underline='always'
      color='secondary'
      target='_blank'
      rel='noopener'
    >
      (Why upgrade?)
    </Link>
  );
};

export default WhyUpgradeLink;
