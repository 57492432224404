import { Accordion } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '../../Auth0Provider';
import { sharedRepStorageKey, AmplitudeEventKeys } from '../../Constants';
import { RepItem } from '../../redux/models/shareDataReps';
import { InsightDispatch, iRootState } from '../../redux/store';
import ShareSectionHeader from './ShareSectionHeader';
import ShareSectionList from './ShareSectionList';
import RepMfrDataAccessStatus from '../../RepMfrDataAccessStatuses';
import trackAmplitudeEvent from './trackAmplitudeEvents';

const RepsRequestDataSelector = function RepsRequestDataSelector(): React.ReactElement | null {
  const vendorId = useSelector(
    (state: iRootState) => state.companyInfo.vendorId
  );
  const allAvailableReps = useSelector(
    (state: iRootState) => state.shareDataReps.reps
  );
  const dispatch = useDispatch<InsightDispatch>();
  const { dataAccessToken } = useAuth0();

  const getReps = useCallback(() => {
    dispatch.shareDataReps.getAvailableReps({
      mfrId: vendorId,
      dataAccessToken
    });
  }, [vendorId, dataAccessToken, dispatch]);

  useEffect(() => {
    getReps();
  }, [getReps]);

  const getSharedReps = function getSharedReps(): RepItem[] {
    const previousSharedReps = localStorage.getItem(sharedRepStorageKey);
    if (previousSharedReps) {
      return JSON.parse(previousSharedReps);
    }
    return [];
  };

  const getPendingRepText = function getPendingRepText(
    repStatus: string,
    repName: string
  ): string {
    return repStatus === RepMfrDataAccessStatus.Pending
      ? `${repName} (Pending their approval)`
      : repName;
  };

  const selectableReps = allAvailableReps
    .filter(r => r.repStatus !== RepMfrDataAccessStatus.Approved)
    .map(r => ({
      id: r.repId,
      text: getPendingRepText(r.repStatus, r.repName),
      preselected: r.repStatus === RepMfrDataAccessStatus.Pending
    }));

  const oldSharedReps = getSharedReps();
  const getApprovedRepText = function getApprovedRepText(
    repId: string,
    repName: string
  ): string {
    if (!oldSharedReps.some(r => r.repId === repId)) {
      return `${repName} - 1 New!`;
    }
    return `${repName}`;
  };

  const approvedReps = allAvailableReps
    .filter(r => r.repStatus === RepMfrDataAccessStatus.Approved)
    .map(r => ({
      id: r.repId,
      text: getApprovedRepText(r.repId, r.repName),
      preselected: false
    }));

  const newSharedReps = approvedReps.filter(
    value => !oldSharedReps.map(a => a.repId).includes(value.id)
  );

  const getApprovedHeaderText = function getApprovedHeaderText(): string {
    return `You are Receiving Rep Data (${approvedReps.length}) - ${newSharedReps.length} New!`;
  };

  const saveSharedReps = function saveSharedReps(
    approvedRepsArg: RepItem[]
  ): void {
    const sharedReps = JSON.stringify([...approvedRepsArg]);
    localStorage.setItem(sharedRepStorageKey, sharedReps);
  };

  saveSharedReps(
    approvedReps.map(r => ({
      repId: r.id,
      repName: r.text,
      repStatus: RepMfrDataAccessStatus.Approved
    }))
  );

  const updateStatusData = function updateStatusData(
    status: string,
    event: string
  ): (ids: string[]) => Promise<void> {
    return async (ids: string[]): Promise<void> => {
      const repStatusList = ids.map(r => ({
        repId: r,
        status
      }));

      await dispatch.shareDataReps.shareData({
        repStatusList,
        dataAccessToken
      });
      trackAmplitudeEvent(ids, event, dispatch);
      await getReps();
    };
  };

  const approvedRepsActions = [
    {
      text: 'Stop Receiving',
      callback: updateStatusData(
        RepMfrDataAccessStatus.RevokedByMfr,
        AmplitudeEventKeys.StopReceivingButtonClicked
      )
    }
  ];

  const selectableRepsActions = [
    {
      text: 'Request Data',
      callback: updateStatusData(
        RepMfrDataAccessStatus.Pending,
        AmplitudeEventKeys.RequestButtonClicked
      )
    }
  ];

  return (
    <>
      <Accordion defaultExpanded>
        <ShareSectionHeader label={getApprovedHeaderText()} />
        <ShareSectionList items={approvedReps} actions={approvedRepsActions} />
      </Accordion>
      <Accordion defaultExpanded>
        <ShareSectionHeader label='Request Data From' />
        <ShareSectionList
          items={selectableReps}
          actions={selectableRepsActions}
        />
      </Accordion>
    </>
  );
};

export default RepsRequestDataSelector;
