import { createModel } from '@rematch/core';
import createDataAccessAxiosInstance from './createDataAccessAxiosInstance';

export type ManufacturerItem = {
  manufacturerId: string;
  manufacturerName: string;
  status: string;
};

export type ShareDataManufacturersState = {
  manufacturers: ManufacturerItem[];
  selectedManufacturers: ManufacturerItem[];
  legalTextDisplayed: boolean;
  consentAccepted: boolean;
  isMfrChecked: boolean;
};

const defaultState: ShareDataManufacturersState = {
  manufacturers: [],
  selectedManufacturers: [],
  legalTextDisplayed: false,
  consentAccepted: false,
  isMfrChecked: false
};

const setManufacturers = function handleSetReps(
  state: ShareDataManufacturersState,
  manufacturers: []
): ShareDataManufacturersState {
  return {
    ...state,
    manufacturers
  };
};

const setLegalTextDisplay = function handleSetLegalTextDisplay(
  state: ShareDataManufacturersState,
  legalTextDisplayed: boolean
): ShareDataManufacturersState {
  return {
    ...state,
    legalTextDisplayed
  };
};

const toggleConsentAcceptance = function handleToggleConsentAcceptance(
  state: ShareDataManufacturersState
): ShareDataManufacturersState {
  return {
    ...state,
    consentAccepted: !state.consentAccepted
  };
};

const checkConsentAcceptance = function handleCheckConsentAcceptance(
  state: ShareDataManufacturersState,
  accepted: boolean
): ShareDataManufacturersState {
  return {
    ...state,
    consentAccepted: accepted
  };
};

const setSelectedManufacturers = function handleSetSelectedManufacturers(
  state: ShareDataManufacturersState,
  selectedManufacturers: []
): ShareDataManufacturersState {
  return {
    ...state,
    selectedManufacturers
  };
};

const setIsMfrChecked = function handleIsMfrChecked(
  state: ShareDataManufacturersState,
  isMfrChecked: boolean
): ShareDataManufacturersState {
  return {
    ...state,
    isMfrChecked
  };
};

const shareDataManufacturers = createModel({
  state: defaultState,
  reducers: {
    setManufacturers,
    setSelectedManufacturers,
    setLegalTextDisplay,
    toggleConsentAcceptance,
    checkConsentAcceptance,
    setIsMfrChecked
  },
  effects: {
    async getAvailableRepManufacturers(payload): Promise<void> {
      const { companyId, dataAccessToken } = payload;
      const axiosApiInstance = createDataAccessAxiosInstance(dataAccessToken);
      const repManufacturersCall = axiosApiInstance.get(
        `/api/insight/rep/${companyId}/manufacturers`
      );
      const { data: manufacturersData } = await repManufacturersCall;
      this.setManufacturers(manufacturersData);
    },
    async shareData(payload): Promise<void> {
      const { mfrStatusList, dataAccessToken } = payload;
      const axiosApiInstance = createDataAccessAxiosInstance(dataAccessToken);
      await axiosApiInstance.post(
        `api/insight/rep/savemfrstatus`,
        mfrStatusList
      );
    }
  }
});

export default shareDataManufacturers;
