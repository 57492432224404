import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  makeStyles,
  DialogContent,
  Box,
  DialogContentText
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Close } from '@material-ui/icons';
import { iRootState, InsightDispatch } from '../../redux/store';
import NotificationMessage from './NotificationMessage';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.contrastText
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

const NotificationDialog = function NotificationDialog(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();
  useEffect(() => {
    dispatch.notificationCenter.loadNotifications();
  }, [dispatch.notificationCenter]);

  const classes = useStyles();
  const open = useSelector(
    (state: iRootState) => state.notificationCenter.open
  );

  const notifications = useSelector(
    (state: iRootState) => state.notificationCenter.notifications
  );
  const unreadNotifications = notifications.filter(n => !n.read).length;
  const titleDetail =
    unreadNotifications > 0 ? ` (${unreadNotifications} new)` : '';

  const onClose = function onClose(): void {
    dispatch.notificationCenter.toggleOpen();
  };

  const notificationMessages = notifications.map(n => (
    <NotificationMessage key={n.key} notification={n} />
  ));

  const noMessages = (
    <Box textAlign='center'>
      <DialogContentText>You have no notifications</DialogContentText>
    </Box>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant='h6'>
          Notifications
          <Box fontStyle='italic' component='span'>
            {titleDetail}
          </Box>
        </Typography>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {notifications.length > 0 ? notificationMessages : noMessages}
      </DialogContent>
    </Dialog>
  );
};

export default NotificationDialog;
