import React from 'react';
import { makeStyles, Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    background: '#82a4c3',
    padding: 0,
    margin: 0
  },
  message: {
    margin: '20, 15, 20, 15',
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 10
  }
}));

const LegalTextMessage = function LegalTextMessage(): React.ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.message}>
      <Divider />
      <Typography style={{ marginTop: 10 }}>
        OPT-IN CONSENT TO SHARE: By approving to share, you consent to
        AutoQuotes sharing certain AQ Insight Rep datawith the manufacturer. The
        AQ Insight Rep data to be shared involves valuable brand, category,
        customer, salesperson, region, and product-level data relating to the
        manufacturer which is normally only available to licensed AQ Insight Rep
        users such as yourself.
      </Typography>
      <Typography>
        Once you approve this request, then the following two actions will
        occur: (1) the AQ Insight Rep data relating to the manufacturer will
        appear in the manufacturer’s dashboard within the AutoQuotes online
        platform; and (2) the requesting manufacturer will be granted access to
        your company’s AQ Insight Rep dashboard within the AutoQuotes online
        platform.
      </Typography>
      <Typography>
        You may opt-out of sharing this data with this manufacturer at any time
        by revoking access to the shared data from the AQ Insight share menu.
      </Typography>
      <Typography>
        AutoQuotes reserves the right to nullify your consent on this matter at
        any time with or without cause.
      </Typography>
    </div>
  );
};

export default LegalTextMessage;
