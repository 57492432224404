import { createModel } from '@rematch/core';

type CompanyInfoState = {
  companyId: string;
  vendorId: string;
  userId: string;
};
const setCompanyId = function handleSetCompanyId(
  state: CompanyInfoState,
  companyId: string
): CompanyInfoState {
  return {
    ...state,
    companyId
  };
};

const setVendorId = function handleSetVendorId(
  state: CompanyInfoState,
  vendorId: string
): CompanyInfoState {
  return {
    ...state,
    vendorId
  };
};

const setUserId = function handleSetUserId(
  state: CompanyInfoState,
  userId: string
): CompanyInfoState {
  return {
    ...state,
    userId
  };
};

const setIds = function handleSetIds(
  state: CompanyInfoState,
  ids: CompanyInfoState
): CompanyInfoState {
  return ids;
};

const companyInfo = createModel({
  state: { companyId: '', vendorId: '' },
  reducers: {
    setCompanyId,
    setVendorId,
    setUserId,
    setIds
  }
});

export default companyInfo;
