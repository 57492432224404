import React from 'react';
import { Hidden } from '@material-ui/core';
import ResetDashboard from '../../Filters/ResetDashboard/ResetDashboard';

const FilterBarResetDashboard = function FilterBarResetDashboard(): React.ReactElement {
  return (
    <Hidden smDown>
      <ResetDashboard />
    </Hidden>
  );
};

export default FilterBarResetDashboard;
