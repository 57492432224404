import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MenuBar from './MenuBar/MenuBar';
import { useAuth0 } from './Auth0Provider';
import NotAuthorized from './NotAuthorized';
import InsightApplication from './InsightApplication/InsightApplication';
import TelemetryProvider from './ApplicationInsights/AppInsightsTelemetryProvider';

const Routes = function Routes(): React.ReactElement {
  const {
    isAuthenticated,
    isAuthorized,
    loginWithRedirect,
    loading
  } = useAuth0();
  if (!isAuthenticated && !loading) {
    loginWithRedirect({});
  }

  const showDashboard = isAuthenticated && isAuthorized;
  const showNotAuthorized = isAuthenticated && !loading && !isAuthorized;
  return (
    <BrowserRouter>
      <TelemetryProvider>
        {isAuthenticated && <MenuBar />}
        <Switch>
          <Route path='/' exact>
            {showDashboard && <InsightApplication />}
            {showNotAuthorized && <NotAuthorized />}
          </Route>
        </Switch>
      </TelemetryProvider>
    </BrowserRouter>
  );
};

export default Routes;
