import React from 'react';
import { Menu } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { InsightDispatch } from '../../redux/store';

const FlyoutIcon = function FlyoutIcon(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();

  const onClick = function handleOnClick(): void {
    dispatch.flyout.toggleOpen();
  };

  return (
    <IconButton color='inherit' onClick={onClick}>
      <Menu />
    </IconButton>
  );
};

export default FlyoutIcon;
