import filters from './filters';
import flyout from './flyout';
import license from './license';
import notificationCenter from './notificationCenter';
import shareCenter from './shareCenter';
import upgradeNotification from './upgradeNotification';
import dashboards from './dashboards';
import shareDataReps from './shareDataReps';
import shareDataManufacturers from './shareDataManufacturers';
import companyInfo from './companyInfo';
import amplitude from './amplitude';

const models = {
  filters,
  flyout,
  license,
  upgradeNotification,
  dashboards,
  shareCenter,
  notificationCenter,
  shareDataReps,
  shareDataManufacturers,
  companyInfo,
  amplitude
};
export default models;
