import { Drawer, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { iRootState, InsightDispatch } from '../../redux/store';
import UserInfo from '../UserInfo';
import CloseFlyout from './CloseFlyout';
import FlyoutCompanySelector from './FlyoutCompanySelector';
import Logout from './Logout';
import FlyoutResetDashboard from './FlyoutResetDashboard';
import LaunchInsightForReps from './LaunchInsightForReps';
import { LicenseType } from '../../redux/models/license';

const useClasses = makeStyles(theme => ({
  drawerContent: {
    width: 250,
    padding: theme.spacing(2)
  },
  paper: { backgroundColor: '#82a4c3', color: theme.palette.common.white },
  logoutButton: {
    marginTop: 100
  }
}));

const FlyoutDrawer = function FlyoutDrawer(): React.ReactElement {
  const open = useSelector((state: iRootState) => state.flyout.open);
  const licenseType = useSelector((state: iRootState) => state.license.type);
  const availableReps = useSelector((state: iRootState) => state.filters.reps);
  const dispatch = useDispatch<InsightDispatch>();
  const classes = useClasses();

  const onClose = function handleOnClose(): void {
    dispatch.flyout.toggleOpen();
  };

  const showInsightForReps =
    licenseType === LicenseType.Pro && availableReps.length > 0;

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      open={open}
      anchor='right'
      onClose={onClose}
    >
      <Grid
        container
        direction='column'
        className={classes.drawerContent}
        spacing={1}
      >
        <Grid item container>
          <UserInfo />
          <CloseFlyout />
        </Grid>
        <Grid item container>
          <FlyoutCompanySelector />
        </Grid>
        {showInsightForReps && (
          <Grid item>
            <LaunchInsightForReps />
          </Grid>
        )}
        <Grid item>
          <FlyoutResetDashboard />
        </Grid>
        <Grid item container justify='center' className={classes.logoutButton}>
          <Logout />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default FlyoutDrawer;
