import { createModel } from '@rematch/core';
import createDataAccessAxiosInstance from './createDataAccessAxiosInstance';

export type RepItem = {
  repId: string;
  repName: string;
  repStatus: string;
};

type ShareDataRepsState = {
  reps: RepItem[];
  selectedReps: RepItem[];
};

const defaultState: ShareDataRepsState = {
  reps: [],
  selectedReps: []
};
const setReps = function handleSetReps(
  state: ShareDataRepsState,
  reps: []
): ShareDataRepsState {
  return {
    ...state,
    reps
  };
};

const shareDataReps = createModel({
  state: defaultState,
  reducers: {
    setReps
  },
  effects: {
    async getAvailableReps(payload): Promise<void> {
      const { mfrId, dataAccessToken } = payload;
      const axiosApiInstance = createDataAccessAxiosInstance(dataAccessToken);
      const mfrRepsCall = axiosApiInstance.get(
        `/api/insight/manufacturer/${mfrId}/reps`
      );
      const { data: repsData } = await mfrRepsCall;
      this.setReps(repsData);
    },
    async shareData(payload): Promise<void> {
      const { repStatusList, dataAccessToken } = payload;
      const axiosApiInstance = createDataAccessAxiosInstance(dataAccessToken);
      await axiosApiInstance.post(
        `api/insight/manufacturer/saverepsstatus`,
        repStatusList
      );
    }
  }
});

export default shareDataReps;
