import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardType } from '../redux/models/dashboards';
import { LicenseType } from '../redux/models/license';
import { InsightDispatch, iRootState } from '../redux/store';
import createDashboardFilters from './FilterBar/createDashboardFilters';

const useStyles = makeStyles(() => ({
  iframe: {
    flex: '1 1 1px'
  }
}));

const InsightDashboard = function InsightDashboard(): React.ReactElement {
  const { sisenseUrl } = window.AutoQuotes.config;
  const classes = useStyles();

  const startDate = useSelector((state: iRootState) => state.filters.startDate);
  const endDate = useSelector((state: iRootState) => state.filters.endDate);
  const filterKey = useSelector((state: iRootState) => state.filters.filterKey);
  const dashboardType = useSelector(
    (state: iRootState) => state.dashboards.dashboardType
  );
  const selectedCompanies = useSelector((state: iRootState) => {
    if (
      dashboardType === DashboardType.MfrRep ||
      dashboardType === DashboardType.Rep
    ) {
      return state.filters.selectedReps;
    }

    return state.filters.selectedManufacturers;
  });

  const licenseType = useSelector((state: iRootState) => state.license.type);
  const {
    freeDashboardId,
    proDashboardId,
    repDashboardId,
    mfrRepDashboardId
  } = useSelector((state: iRootState) => state.dashboards.dashboards);
  const getDashboardId = function getDashboardId(): string {
    switch (dashboardType) {
      case DashboardType.Rep:
        return repDashboardId;
      case DashboardType.Mfr:
        return licenseType === LicenseType.Pro
          ? proDashboardId
          : freeDashboardId;
      case DashboardType.MfrRep:
        return mfrRepDashboardId;
      default:
        return freeDashboardId;
    }
  };

  const dashboardId = getDashboardId();

  const dispatch = useDispatch<InsightDispatch>();
  const onLoad = function onLoad(): void {
    dispatch.filters.getAvailableCompanies(DashboardType.Mfr);
    dispatch.filters.getAvailableCompanies(DashboardType.Rep);
  };

  const scope = createDashboardFilters({
    startDate,
    endDate,
    dashboardType,
    licenseType,
    selectedCompanies
  });
  const filters = encodeURIComponent(JSON.stringify(scope));

  const dashboardKey = `${filters}${filterKey}`;

  const dashboardUrl = `${sisenseUrl}/app/main#/dashboards/${dashboardId}?embed=true&r=false&filter=${filters}`;

  return (
    <iframe
      className={classes.iframe}
      key={dashboardKey}
      title='Sisense Dashboard'
      width='100%'
      frameBorder='0'
      src={dashboardUrl}
      onLoad={onLoad}
    />
  );
};
export default InsightDashboard;
