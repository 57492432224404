import { createModel } from '@rematch/core';

type ShareCenterState = {
  open: boolean;
};

export const sharedRepStorageKey = 'sharedReps';

const toggleOpen = function handleToggleOpen(
  state: ShareCenterState
): ShareCenterState {
  return {
    ...state,
    open: !state.open
  };
};

const shareCenter = createModel({
  state: {
    open: false
  },
  reducers: {
    toggleOpen
  }
});

export default shareCenter;
