import { Chip } from '@material-ui/core';
import React from 'react';

const ChipInfoLabel = function ChipInfoLabel(): React.ReactElement {
  return (
    <Chip
      label='Contact Us - sales@AQ-FES.com'
      color='primary'
      component='a'
      href='mailto:sales@aq-fes.com'
      clickable
    />
  );
};

export default ChipInfoLabel;
