import { createModel } from '@rematch/core';
import amplitudeSdk from 'amplitude-js';

type AmplitudeState = {
  isInitialized: boolean;
};
type AmplitudeRootState = {
  amplitude: AmplitudeState;
  dashboards: { dashboardType: string };
  companyInfo: { userId: string };
};
const defaultState: AmplitudeState = {
  isInitialized: false
};
type AmplitudeEvent = {
  eventType: string;
  eventProperties: {};
};
const setInitialized = function handleSetInitialized(
  state: AmplitudeState,
  isInitialized: boolean
): AmplitudeState {
  return {
    ...state,
    isInitialized
  };
};

const amplitude = createModel({
  state: defaultState,
  reducers: {
    setInitialized
  },
  effects: {
    init(apiKey: string): void {
      try {
        const instance = amplitudeSdk.getInstance();
        instance.init(apiKey);
        this.setInitialized(true);
      } catch (err) {
        this.setInitialized(false);
      }
    },
    setProperties(properties: {}, state: AmplitudeRootState): void {
      try {
        const instance = amplitudeSdk.getInstance();
        instance.setUserId(state.companyInfo.userId);
        instance.setUserProperties(properties);
      } catch (err) {
        throw new Error('Error setting user properties.');
      }
    },
    sendEvent(event: AmplitudeEvent, state: AmplitudeRootState): void {
      if (!state.amplitude.isInitialized)
        throw new Error('Amplitude not initialized.');
      try {
        const instance = amplitudeSdk.getInstance();
        const eventProperties = {
          ...event.eventProperties,
          dashboardType: state.dashboards.dashboardType
        };
        instance.logEvent(event.eventType, eventProperties);
      } catch {
        throw new Error('Error connecting to Amplitude.');
      }
    }
  }
});

export default amplitude;
