import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InsightDispatch, iRootState } from '../../redux/store';
import DateSelector from './DateSelector';

const EndDateSelector = function EndDateSelector(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();

  const startDate = useSelector((state: iRootState) => state.filters.startDate);
  const endDate = useSelector((state: iRootState) => state.filters.endDate);
  const onChange = function handleOnChange(date: MaterialUiPickersDate): void {
    if (date === null) {
      return;
    }
    dispatch.filters.setEndDate(date);
  };

  return (
    <DateSelector
      value={endDate}
      onChange={onChange}
      maxDate={new Date()}
      minDate={startDate}
    />
  );
};

export default EndDateSelector;
