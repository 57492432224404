import React from 'react';
import { Button } from '@material-ui/core';
import { useAuth0 } from '../../Auth0Provider';
import { logoutSisense } from '../../SisenseAPI';

const Logout = function Logout(): React.ReactElement | null {
  const { user, logout, isAuthorized } = useAuth0();

  if (!user) {
    return null;
  }

  const logoutClick = async function handleLogoutClick(): Promise<void> {
    if (isAuthorized) {
      await logoutSisense();
    }

    logout({
      returnTo: window.location.href,
      client_id: window.AutoQuotes.config.clientId
    });
  };

  return (
    <Button color='inherit' variant='outlined' onClick={logoutClick}>
      Logout
    </Button>
  );
};

export default Logout;
