import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InsightDispatch, iRootState } from '../../redux/store';
import DateSelector from './DateSelector';

const StartDateSelector = function StartDateSelector(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();

  const startDate = useSelector((state: iRootState) => state.filters.startDate);
  const endDate = useSelector((state: iRootState) => state.filters.endDate);
  const onChange = function handleOnChange(date: MaterialUiPickersDate): void {
    if (date == null) {
      return;
    }

    dispatch.filters.setStartDate(date);
  };

  return (
    <DateSelector
      value={startDate}
      onChange={onChange}
      maxDate={endDate}
      minDate={new Date(2019, 4, 1)}
    />
  );
};

export default StartDateSelector;
