import { DashboardType } from '../../redux/models/dashboards';
import { LicenseType } from '../../redux/models/license';

type CreateDashboardFiltersParams = {
  startDate: Date;
  endDate: Date;
  dashboardType: DashboardType;
  licenseType: LicenseType;
  selectedCompanies: string[];
};

interface Filter {
  table: string;
  column: string;
  dim: string;
  datatype: string;
}

interface DateFilter extends Filter {
  filter: {
    custom: boolean;
    from: Date;
    to: Date;
  };
}

interface MembersFilters extends Filter {
  filter: {
    members: string[];
  };
}

interface ExclusionFilter extends Filter {
  filter: {
    exclude: {
      members: string[];
    };
  };
}

interface Jaql {
  jaql: MembersFilters | DateFilter | ExclusionFilter | Filter;
}

const createDateFilter = function createDateFilter(
  startDate: Date,
  endDate: Date
): Jaql {
  return {
    jaql: {
      table: 'Date Dim',
      column: 'Unified Date',
      dim: '[Date Dim.Unified Date (Calendar)]',
      datatype: 'datetime',
      filter: {
        custom: true,
        from: startDate,
        to: endDate
      }
    }
  };
};

const createCompanyTableName = function createCompanyTableName(
  dashboardType: DashboardType
): string {
  return dashboardType === DashboardType.Mfr
    ? 'DIM_ALL_PRODUCTS'
    : 'DIM_COMPANY_REP';
};

const createCompanyColumnName = function createCompanyColumnName(
  dashboardType: DashboardType,
  licenseType: LicenseType
): string {
  if (dashboardType === DashboardType.Mfr)
    return licenseType === LicenseType.Free ? 'VENDOR_NAME' : 'VENDOR_NAME_DUP';
  return 'COMPANY_NAME';
};

const createDimension = function createDimension(
  table: string,
  column: string
): string {
  return `[${table}.${column}]`;
};

const createMembersFilter = function createMembersFilter(
  table: string,
  column: string,
  members: string[]
): Jaql {
  const dim = createDimension(table, column);

  return {
    jaql: {
      table,
      column,
      dim,
      datatype: 'text',
      filter: {
        members
      }
    }
  };
};

const createFilter = function createFilter(
  table: string,
  column: string
): Jaql {
  const dim = createDimension(table, column);

  return {
    jaql: {
      table,
      column,
      dim,
      datatype: 'text',
      filter: {
        members: []
      }
    }
  };
};

const createCompanyFilter = function createCompanyFilter(
  licenseType: LicenseType,
  dashboardType: DashboardType,
  selectedCompanies: string[]
): Jaql {
  const table = createCompanyTableName(dashboardType);
  const column = createCompanyColumnName(dashboardType, licenseType);
  return createMembersFilter(table, column, selectedCompanies);
};

const createEmptyVendorFilter = function createEmptyVendorFilter(): Jaql {
  return createFilter('DIM_ALL_PRODUCTS', 'VENDOR_NAME');
};

const createChannelFilter = function createChannelFilter(): Jaql {
  const table = 'DIM_COMPANY';
  const column = 'Channel Adjusted';
  return createFilter(table, column);
};

const createSubcategoryFilter = function createSubcategoryFilter(): Jaql {
  const table = 'DIM_ALL_PRODUCTS';
  const column = 'Product Sub Category';
  return createFilter(table, column);
};

const createCategoryFilter = function createCategoryFilter(): Jaql {
  const table = 'DIM_ALL_PRODUCTS';
  const column = 'Product Category';
  return createFilter(table, column);
};

const createStateFilter = function createStateFilter(): Jaql {
  const table = 'DIM_COMPANY';
  const column = 'STATE (deduped)';
  return createFilter(table, column);
};

const createCountryFilter = function createCountryFilter(): Jaql {
  const table = 'DIM_COMPANY';
  const column = 'COUNTRY (deduped)';
  return createFilter(table, column);
};

const createCountryOfProductFilter = function createCountryOfProduct(): Jaql {
  const table = 'Currency Selector';
  const column = 'Country of Product';
  return createFilter(table, column);
};

const createCustomerAddressesFilter = function createCustomerAddressesFilter(): Jaql {
  const table = 'DIM_COMPANY_CUSTOMER_ADDRESSES';
  const column = 'CUSTOMER_ADDRESS_NAME';
  return createFilter(table, column);
};

const createProjectNameFilter = function createProjectNameFilter(): Jaql {
  const table = 'DIM_PROJECT';
  const column = 'PROJECT_NAME';
  return createFilter(table, column);
};

const createFiltersForFreeManufacturerDashboard = function createFiltersForFreeManufacturerDashboard({
  startDate,
  endDate,
  dashboardType,
  licenseType,
  selectedCompanies
}: CreateDashboardFiltersParams): Jaql[] {
  const dateFilter = createDateFilter(startDate, endDate);
  const companyFilter = createCompanyFilter(
    licenseType,
    dashboardType,
    selectedCompanies
  );
  const channelFilter = createChannelFilter();
  return [dateFilter, companyFilter, channelFilter];
};

const createFiltersForProManufacturerDashboard = function createFiltersForProManufacturerDashboard(
  dashboardFilterParams: CreateDashboardFiltersParams
): Jaql[] {
  const subcategoryFilter = createSubcategoryFilter();
  const productCategoryFilter = createCategoryFilter();
  const stateFilter = createStateFilter();
  const countryFilter = createCountryFilter();
  const countryOfProductFilter = createCountryOfProductFilter();

  return [
    ...createFiltersForFreeManufacturerDashboard(dashboardFilterParams),
    subcategoryFilter,
    productCategoryFilter,
    stateFilter,
    countryFilter,
    countryOfProductFilter
  ];
};

const createFiltersForManufacturerRepDashboard = function createFiltersForManufacturerRepDashboard({
  startDate,
  endDate,
  dashboardType,
  licenseType,
  selectedCompanies
}: CreateDashboardFiltersParams): Jaql[] {
  const dateFilter = createDateFilter(startDate, endDate);
  const repFilter = createCompanyFilter(
    licenseType,
    dashboardType,
    selectedCompanies
  );
  const vendorFilter = createEmptyVendorFilter();
  const countryOfProductFilter = createCountryOfProductFilter();
  const customerAddressesFilter = createCustomerAddressesFilter();
  const projectNameFilter = createProjectNameFilter();
  return [
    dateFilter,
    vendorFilter,
    repFilter,
    customerAddressesFilter,
    projectNameFilter,
    countryOfProductFilter
  ];
};

const createFiltersForRepDashboard = function createFiltersForRepDashboard({
  startDate,
  endDate,
  licenseType,
  dashboardType,
  selectedCompanies
}: CreateDashboardFiltersParams): Jaql[] {
  const dateFilter = createDateFilter(startDate, endDate);
  const repFilter = createCompanyFilter(
    licenseType,
    dashboardType,
    selectedCompanies
  );
  const customerAddressesFilter = createCustomerAddressesFilter();
  const projectNameFilter = createProjectNameFilter();
  const countryOfProductFilter = createCountryOfProductFilter();
  return [
    dateFilter,
    repFilter,
    customerAddressesFilter,
    projectNameFilter,
    countryOfProductFilter
  ];
};

const createDashboardFilters = function createDashboardFilters(
  dashboardFilterParams: CreateDashboardFiltersParams
): Jaql[] {
  const { licenseType, dashboardType } = dashboardFilterParams;

  if (licenseType === LicenseType.Free && dashboardType === DashboardType.Mfr) {
    return createFiltersForFreeManufacturerDashboard(dashboardFilterParams);
  }

  if (licenseType === LicenseType.Pro && dashboardType === DashboardType.Mfr) {
    return createFiltersForProManufacturerDashboard(dashboardFilterParams);
  }

  if (
    licenseType === LicenseType.Pro &&
    dashboardType === DashboardType.MfrRep
  ) {
    return createFiltersForManufacturerRepDashboard(dashboardFilterParams);
  }

  if (licenseType === LicenseType.Rep && dashboardType === DashboardType.Rep) {
    return createFiltersForRepDashboard(dashboardFilterParams);
  }

  return [];
};

export default createDashboardFilters;
