import React from 'react';
import InsightDashboard from './InsightDashboard';
import FilterBar from './FilterBar/FilterBar';
import UpgradeAQInsightProNotification from '../Notifications/UpgradeAQInsightProNotification';

const InsightApplication = function InsightApplication(): React.ReactElement {
  return (
    <>
      <FilterBar />
      <InsightDashboard />
      <UpgradeAQInsightProNotification />
    </>
  );
};

export default InsightApplication;
