import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  LogoutOptions
} from '@auth0/auth0-spa-js';
import Axios, { AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isAfter } from 'date-fns';
import {
  AQInsightProLicenseClaim,
  CompanyNameClaim,
  CompanyRegionClaim,
  AQInsightRepLicenseClaim,
  repNotificationStartDate
} from './Constants';
import { LicenseType } from './redux/models/license';
import { DashboardType } from './redux/models/dashboards';
import { InsightDispatch } from './redux/store';

export interface Auth0AppState {
  targetUrl?: string;
}

export interface Auth0User {
  family_name: string;
  given_name: string;
  [CompanyNameClaim]: string;
  [CompanyRegionClaim]: string;
}

export interface Auth0Hook {
  isAuthenticated: boolean;
  isAuthorized: boolean;
  loading: boolean;
  dataAccessLevel: string;
  dataAccessToken: string;
  loginWithRedirect: (appState: Auth0AppState) => void;
  handleRedirectCallback: () => void;
  logout: (logoutOptions: LogoutOptions) => void;
  user?: Auth0User;
}

interface Auth0ProviderOptions extends Auth0ClientOptions {
  children: React.ReactElement | React.ReactElement[];
  onRedirectCallback: (appState: Auth0AppState) => void;
}

export const Auth0Context = React.createContext<Auth0Hook>({
  isAuthenticated: false,
  isAuthorized: false,
  loading: false,
  dataAccessLevel: 'None',
  dataAccessToken: '',
  loginWithRedirect: () => {},
  handleRedirectCallback: () => {},
  logout: () => {}
});
export const useAuth0 = (): Auth0Hook => useContext<Auth0Hook>(Auth0Context);

const Auth0Provider = function Auth0Provider({
  children,
  onRedirectCallback,
  ...initOptions
}: Auth0ProviderOptions): React.ReactElement {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [user, setUser] = useState<any>();

  // eslint-disable-next-line
  const [auth0Client, setAuth0] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [dataAccessLevel, setDataAccessLevel] = useState<string>('None');
  const [dataAccessToken, setDataAccessToken] = useState<string>('');
  const dispatch = useDispatch<InsightDispatch>();

  useEffect(() => {
    const handleRedirectCallback = async function handleRedirectCallback(
      client: Auth0Client
    ): Promise<void> {
      if (window.location.search.includes('code=')) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }
    };

    const validateJwt = async function validateJwt(
      token: string
    ): Promise<AxiosResponse> {
      const body = {};
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
        baseURL: window.AutoQuotes.config.insightApiUrl
      };

      return Axios.post('api/auth/sign-in', body, config);
    };

    const isRep = function isRep(authorizations: string[]): boolean {
      return authorizations.some(a => a === AQInsightRepLicenseClaim);
    };

    const isPro = function isPro(authorizations: string[]): boolean {
      return authorizations.some(a => a === AQInsightProLicenseClaim);
    };

    const getNotificationStartDateFromLocal = function getNotificationStartDateFromLocal():
      | string
      | null {
      return localStorage.getItem('repNotificationStartDate');
    };

    const isNotificationDateStarted = function isNotificationDateStarted(): boolean {
      const overrideDate = getNotificationStartDateFromLocal();
      const startDate = !overrideDate ? null : new Date(overrideDate);
      const now = new Date(Date.now());
      return startDate
        ? isAfter(new Date(startDate), now)
        : isAfter(repNotificationStartDate, now);
    };

    const displayUpgradeAQInsightProNotification = function displayUpgradeAQInsightProNotification(
      authorizations: string[]
    ): void {
      const isRepUser = isRep(authorizations);
      const isProUser = isPro(authorizations);
      if (isRepUser) {
        return;
      }

      const showUpgradeNotification = !isProUser && isNotificationDateStarted();
      dispatch.upgradeNotification.setVisible(showUpgradeNotification);
    };

    const getLicenseType = function getLicenseType(
      authorizations: string[]
    ): LicenseType {
      if (isRep(authorizations)) {
        return LicenseType.Rep;
      }

      if (isPro(authorizations)) {
        return LicenseType.Pro;
      }

      return LicenseType.Free;
    };

    const setLicenseType = function setLicenseType(
      authorizations: string[]
    ): void {
      const licenseType = getLicenseType(authorizations);
      dispatch.license.updateType(licenseType);
    };

    const setDashboardType = function setDashboardType(
      authorizations: string[]
    ): void {
      const isRepUser = isRep(authorizations);
      const defaultDashboardType = isRepUser
        ? DashboardType.Rep
        : DashboardType.Mfr;
      dispatch.dashboards.updateDashboardType(defaultDashboardType);
    };

    const setDataAccessLevelFromAuthorizations = function setDataAccessLevelFromAuthorizations(
      authorizations: string[]
    ): void {
      const dataAccessLevelAuthorizations = authorizations.find(p =>
        p.startsWith('AQDataAccess')
      );
      if (dataAccessLevelAuthorizations) {
        setDataAccessLevel(dataAccessLevelAuthorizations);
      }
    };

    const setAuthorized = function setNotAuthorized(
      authorizations: string[]
    ): void {
      const authorized =
        authorizations &&
        authorizations.length > 0 &&
        !authorizations.some(a => a === 'None');

      setIsAuthorized(authorized);
    };

    const setDashboardIds = function setDashboardIds(
      freeDashboardId: string,
      proDashboardId: string,
      repDashboardId: string,
      mfrRepDashboardId: string
    ): void {
      dispatch.dashboards.setDashboardIds({
        freeDashboardId,
        proDashboardId,
        repDashboardId,
        mfrRepDashboardId
      });
    };

    const checkAuthorization = async function checkAuthorization(
      client: Auth0Client
    ): Promise<void> {
      const token = await client.getTokenSilently({
        scope: 'read:insight',
        audience: window.AutoQuotes.config.insightApiAudience
      });

      const response = await validateJwt(token);

      const {
        authorizations,
        companyId,
        userId,
        vendorId,
        email,
        userName,
        companyName,
        repDashboard,
        mfrFreeDashboard,
        mfrProDashboard,
        mfrRepDashboard
      } = response.data;

      setAuthorized(authorizations);
      setDashboardIds(
        mfrFreeDashboard,
        mfrProDashboard,
        repDashboard,
        mfrRepDashboard
      );
      setDashboardType(authorizations);
      setLicenseType(authorizations);
      setDataAccessLevelFromAuthorizations(authorizations);
      setDataAccessToken(token);
      displayUpgradeAQInsightProNotification(authorizations);
      dispatch.companyInfo.setIds({ companyId, vendorId, userId });
      dispatch.notificationCenter.setShowRepNotification();
      dispatch.notificationCenter.loadNotifications();
      const userProperties = {
        companyId,
        companyName,
        userName,
        email
      };
      dispatch.amplitude.setProperties(userProperties);
    };

    const initAuth0 = async (): Promise<void> => {
      const client = await createAuth0Client(initOptions);
      setAuth0(client);

      await handleRedirectCallback(client);

      const userAuthenticatedInAuth0 = await client.isAuthenticated();

      setIsAuthenticated(userAuthenticatedInAuth0);

      if (userAuthenticatedInAuth0) {
        try {
          await checkAuthorization(client);
        } catch {
          setIsAuthorized(false);
        }

        const userFromAuth0 = await client.getUser();
        setUser(userFromAuth0);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const handleRedirectCallback = async (): Promise<void> => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const userFromAuth0 = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(userFromAuth0);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isAuthorized,
        user,
        loading,
        dataAccessLevel,
        dataAccessToken,
        handleRedirectCallback,
        loginWithRedirect: (appState: Auth0AppState): void =>
          auth0Client.loginWithRedirect(appState),
        logout: (logoutOptions: LogoutOptions): void => {
          auth0Client.logout(logoutOptions);
        }
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
