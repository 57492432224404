import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Provider as ReactReduxProvider } from 'react-redux';
import AqInsightTheme from './AqInsightTheme';
import Auth0Provider, { Auth0AppState } from './Auth0Provider';
import store from './redux/store';
import Routes from './Routes';

declare global {
  interface Window {
    AutoQuotes: {
      config: {
        sisenseUrl: string;
        domain: string;
        clientId: string;
        aqApiUrl: string;
        insightApiUrl: string;
        insightApiAudience: string;
        datasource: string;
        amplitudeApiKey: string;
      };
    };
  }
}

const AQInsight = function AQInsight(): React.ReactElement {
  if (window.AutoQuotes.config.amplitudeApiKey) {
    store.dispatch.amplitude.init(window.AutoQuotes.config.amplitudeApiKey);
  }
  const onRedirectCallback = function onRedirectCallback(
    appState: Auth0AppState
  ): void {
    // eslint-disable-next-line no-self-assign
    window.location.hash = window.location.hash;
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  return (
    <ReactReduxProvider store={store}>
      <MuiThemeProvider theme={AqInsightTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Auth0Provider
            domain={window.AutoQuotes.config.domain}
            client_id={window.AutoQuotes.config.clientId}
            scope='read:insight'
            audience={window.AutoQuotes.config.insightApiAudience}
            redirect_uri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
          >
            <Routes />
          </Auth0Provider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ReactReduxProvider>
  );
};

export default AQInsight;
