import { createModel } from '@rematch/core';

type upgradeNotificationState = {
  visible: boolean;
};

const setVisible = function setVisible(
  state: upgradeNotificationState,
  visible: boolean
): upgradeNotificationState {
  return {
    ...state,
    visible
  };
};

const upgradeNotification = createModel({
  state: {
    visible: false
  },
  reducers: {
    setVisible
  }
});

export default upgradeNotification;
