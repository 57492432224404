import { Typography, Box } from '@material-ui/core';
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import WhyUpgradeLink from './WhyUpgradeLink';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      margin: 0,
      fontSize: 12,
      paddingLeft: 16,
      paddingRight: 16
    }
  })
);

const UpgradeAQInsightProDescription = function UpgradeAQInsightProDescription(): React.ReactElement {
  const classes = useStyles();

  const benefits = `Benefits of Insight Pro `;
  const benefitsItems = [
    'Ability to Access Multiple Brands/Companies',
    'Download & Export Widget Data',
    'Track Industry Trends',
    'Product-Level Data'
  ];

  return (
    <>
      <Typography color='primary'>
        <Box fontWeight='fontWeightBold'>
          {benefits}
          <WhyUpgradeLink />
        </Box>
      </Typography>
      <ul className={classes.list}>
        {benefitsItems.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </>
  );
};

export default UpgradeAQInsightProDescription;
