import { AmplitudeEventKeys } from '../../Constants';
import { InsightDispatch } from '../../redux/store';

type MfrEvent = {
  mfrId: string;
};
type RepEvent = {
  repId: string;
};
const mfrEvents = [
  AmplitudeEventKeys.StopReceivingButtonClicked,
  AmplitudeEventKeys.RequestButtonClicked
];

const getPropertiesForEvents = function getPropertiesForEvents(
  id: string,
  eventType: string
): MfrEvent | RepEvent {
  return mfrEvents.includes(eventType) ? { repId: id } : { mfrId: id };
};

const trackAmplitudeEvent = function trackAmplitudeEvent(
  ids: string[],
  eventType: string,
  dispatch: InsightDispatch
): void {
  ids.forEach(id => {
    const eventProperties = getPropertiesForEvents(id, eventType);

    dispatch.amplitude.sendEvent({
      eventType,
      eventProperties
    });
  });
};

export default trackAmplitudeEvent;
