import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { iRootState } from '../redux/store';
import UpgradeAQInsightProHeader from './UpgradeAQInsightProHeader';
import UpgradeAQInsightProDescription from './UpgradeAQInsightProDescription';
import ChipInfoLabel from './ChipInfoLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 355,
      backgroundColor: 'white'
    },
    emailContent: {
      padding: theme.spacing(1),
      margin: 0,
      paddingTop: 8
    }
  })
);

const UpgradeAQInsightProNotification = function UpgradeAQInsightProNotification(): React.ReactElement {
  const classes = useStyles();

  const visible = useSelector(
    (state: iRootState) => state.upgradeNotification.visible
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={visible}
    >
      <Paper className={classes.paper} elevation={3}>
        <UpgradeAQInsightProHeader />
        <UpgradeAQInsightProDescription />
        <Grid container justify='center' className={classes.emailContent}>
          <ChipInfoLabel />
        </Grid>
      </Paper>
    </Snackbar>
  );
};

export default UpgradeAQInsightProNotification;
