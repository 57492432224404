import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Person } from '@material-ui/icons';
import React from 'react';
import { useAuth0 } from '../Auth0Provider';

const useStyles = makeStyles(theme => ({
  username: { marginLeft: theme.spacing(1), flexGrow: 1 }
}));

const UserInfo = function UserInfo(): React.ReactElement | null {
  const { user } = useAuth0();
  const classes = useStyles();
  if (!user) {
    return null;
  }
  const userDisplayName = `${user.given_name} ${user.family_name}`;

  return (
    <>
      <Person />
      <Typography className={classes.username}>{userDisplayName}</Typography>
    </>
  );
};

export default UserInfo;
