import { AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

type ShareSectionHeaderProps = {
  label: string;
};

const useClasses = makeStyles(theme => ({
  root: {
    backgroundColor: '#82a4c3',
    color: theme.palette.common.white
  }
}));

const ShareSectionHeader = function ShareSectionHeader({
  label
}: ShareSectionHeaderProps): React.ReactElement {
  const classes = useClasses();
  return (
    <AccordionSummary expandIcon={<ExpandMore />} classes={classes}>
      <Typography>{label}</Typography>
    </AccordionSummary>
  );
};

export default ShareSectionHeader;
