import React, { ReactElement } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { subDays } from 'date-fns';
import { InsightDispatch } from '../../redux/store';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.white
  },
  label: {
    justifyContent: 'flex-start'
  }
}));

interface PresetProps {
  rangeSize: number;
}

const PresetDateRangeButton = function PresetDateRangeButton({
  rangeSize
}: PresetProps): ReactElement {
  const dispatch = useDispatch<InsightDispatch>();
  const classes = useStyles();

  const changeRange = function changeRange(): void {
    const start = subDays(new Date(Date.now()), rangeSize);
    dispatch.filters.setStartDate(start);
    dispatch.filters.setEndDate(new Date(Date.now()));
  };

  const buttonLabel = `Last ${rangeSize} days`;

  return (
    <Button
      fullWidth
      className={classes.button}
      classes={{ label: classes.label }}
      onClick={changeRange}
    >
      {buttonLabel}
    </Button>
  );
};

export default PresetDateRangeButton;
