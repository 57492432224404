import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { InsightDispatch } from '../../redux/store';

const useStyles = makeStyles(theme => ({
  resetDashboard: {
    color: '#fff',
    fontSize: theme.typography.fontSize
  }
}));

const ResetDashboard = function ResetDashboard(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();
  const onClick = function handleOnClick(): void {
    dispatch.filters.resetDashboard();
  };
  const classes = useStyles();

  return (
    <Button className={classes.resetDashboard} onClick={onClick}>
      Reset Dashboard
    </Button>
  );
};

export default ResetDashboard;
