import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '../Auth0Provider';
import { LicenseType } from '../redux/models/license';
import { iRootState } from '../redux/store';
import CompanyInfo from './CompanyInfo';
import HeaderIcons from './HeaderIcons';
import FlyoutDrawer from './Flyout/FlyoutDrawer';
import ShareDialog from './Share/ShareDialog';
import NotificationDialog from './Notifications/NotificationDialog';
import { DashboardType } from '../redux/models/dashboards';

type LogoProps = {
  src: string;
  alt: string;
};

const useStyles = makeStyles(theme => ({
  title: { flexGrow: 1, paddingLeft: theme.spacing(1) }
}));

const MenuBar = function MenuBar(): React.ReactElement {
  const getLogoProps = function getLogoPropsByLicenseType(
    type: LicenseType,
    dashboardType: DashboardType
  ): LogoProps {
    if (
      dashboardType === DashboardType.Rep ||
      dashboardType === DashboardType.MfrRep
    ) {
      return { src: 'InsightReps_Logo.png', alt: 'AQ Insight for Reps Logo' };
    }

    if (type === LicenseType.Pro) {
      return { src: 'InsightPro_Logo.png', alt: 'AQ Insight Pro Logo' };
    }

    return { src: 'logo.png', alt: 'AQ Insight Logo' };
  };

  const styles = useStyles();
  const { isAuthenticated } = useAuth0();
  const licenseType = useSelector((state: iRootState) => state.license.type);
  const dashboardType = useSelector(
    (state: iRootState) => state.dashboards.dashboardType
  );
  const { src, alt } = getLogoProps(licenseType, dashboardType);

  return (
    <AppBar position='static'>
      <Toolbar>
        <img width='145' height='50' src={src} alt={alt} />
        <div className={styles.title}>{isAuthenticated && <CompanyInfo />}</div>
        {isAuthenticated && <HeaderIcons />}
      </Toolbar>
      <FlyoutDrawer />
      <ShareDialog />
      <NotificationDialog />
    </AppBar>
  );
};

export default MenuBar;
