import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LicenseType } from '../../redux/models/license';
import { InsightDispatch, iRootState } from '../../redux/store';
import MfrsRequestDataSelector from './MfrsRequestDataSelector';
import RepsRequestDataSelector from './RepsRequestDataSelector';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.contrastText
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  dialogContent: {
    margin: 0,
    padding: 0
  }
}));

const ShareDialog = function ShareDialog(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();

  const classes = useStyles();
  const open = useSelector((state: iRootState) => state.shareCenter.open);
  const licenseType = useSelector((state: iRootState) => state.license.type);

  const getDataSelector = function getDataSelector(): React.ReactElement | null {
    if (licenseType === LicenseType.Rep) {
      return <MfrsRequestDataSelector />;
    }

    if (licenseType === LicenseType.Pro) {
      return <RepsRequestDataSelector />;
    }

    return null;
  };

  const onClose = function onClose(): void {
    dispatch.shareCenter.toggleOpen();
    dispatch.shareDataManufacturers.checkConsentAcceptance(false);
    dispatch.shareDataManufacturers.setIsMfrChecked(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      scroll='paper'
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant='h6'>Share</Typography>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {getDataSelector()}
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
