import React from 'react';
import Share from '@material-ui/icons/Share';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { InsightDispatch } from '../../redux/store';
import { AmplitudeEventKeys } from '../../Constants';

const ShareIcon = function ShareIcon(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();
  const onClick = function handleOnClick(): void {
    dispatch.shareCenter.toggleOpen();
    dispatch.amplitude.sendEvent({
      eventType: AmplitudeEventKeys.ShareButtonClicked
    });
  };

  return (
    <IconButton color='inherit' onClick={onClick}>
      <Share />
    </IconButton>
  );
};

export default ShareIcon;
