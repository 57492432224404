import { makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type DateSelectorProps = {
  value: Date;
  minDate: Date;
  maxDate: Date;
  onChange: (date: MaterialUiPickersDate) => void;
};

const useStyles = makeStyles(theme => ({
  inputProps: { color: '#FFF' },
  box: {
    width: '90px',
    paddingLeft: theme.spacing(1)
  }
}));

const DateSelector = function DateSelector({
  value,
  onChange,
  minDate,
  maxDate
}: DateSelectorProps): React.ReactElement {
  const classes = useStyles();

  return (
    <DatePicker
      className={classes.box}
      variant='inline'
      disableToolbar
      format='MM/dd/yyyy'
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      onChange={onChange}
      InputProps={{ className: classes.inputProps, disableUnderline: true }}
    />
  );
};

export default DateSelector;
