import { createModel } from '@rematch/core';
import { isAfter, isBefore } from 'date-fns';
import {
  repNotificationStartDate,
  showRepNotificationKey
} from '../../Constants';
import { LicenseType } from './license';

type Notification = {
  key: string;
  title: string;
  message: string;
  read: boolean;
  startDate: Date;
};

type NotificationCenterState = {
  open: boolean;
  expandedNotification: string;
  notifications: Notification[];
};

const toggleOpen = function handleToggleOpen(
  state: NotificationCenterState
): NotificationCenterState {
  return {
    ...state,
    open: !state.open
  };
};

const markNotificationAsRead = function handleMarkNotificationAsRead(
  state: NotificationCenterState,
  notificationKey: string
): NotificationCenterState {
  const { notifications } = state;
  const notificationToMarkAsRead = notifications.find(
    n => n.key === notificationKey
  );
  if (!notificationToMarkAsRead) {
    return state;
  }

  const notificationIndex = notifications.indexOf(notificationToMarkAsRead);

  const readNotification = { ...notificationToMarkAsRead, read: true };

  const allNotifications = [
    ...notifications.slice(0, notificationIndex),
    readNotification,
    ...notifications.slice(notificationIndex + 1)
  ];

  return { ...state, notifications: allNotifications };
};

const expandNotification = function handleExpandNotification(
  state: NotificationCenterState,
  notificationKey: string
): NotificationCenterState {
  return {
    ...state,
    expandedNotification: notificationKey
  };
};

const repDataRemovalNotificationPro = {
  key: 'repDataRemoval',
  title: 'Encourage your Reps to share their data!',
  message:
    '<div><b>AQ Insight will be getting an update in September</b>. With this update we will be introducing a new <b>Manufacturer Ranking Feature</b> that will provide a view of where your company ranks compared to your competition.</div><p><div>Additionally, we will be removing Rep data from the main Insight Pro dashboard in an effort to refine your product&apos;s quoting activity. <b>BUT</b> we will be introducing an exciting new feature that allows you to request specific Rep&apos;s data so that you have access to a Rep specific dashboard containing much more detail on who is quoting your products.</div>',
  read: false,
  startDate: repNotificationStartDate
};
const repDataRemovalNotificationProNotLogged = {
  key: 'repDataRemoval',
  title: 'Welcome to AQ Insight Pro!',
  message:
    '<div><b>With the recent updates to AQ Insight Pro</b> we have introduced a new <b>Manufacturer Ranking Feature</b> that provides you a view of where your company ranks compared to your competition.</div></p><div>Additionally, we have removed Rep data from the main Insight Pro dashboard in an effort to refine your product&apos;s quoting activity. <b>BUT</b> we have introduced an exciting new feature that allows you to request specific Rep&apos;s data so that you have access to a Rep specific dashboard containing much more detail on who is quoting your products.</div>',
  read: false,
  startDate: repNotificationStartDate
};
const repDataRemovalNotificationFree = {
  key: 'repDataRemoval',
  title: 'Sign up for AQ Insight Pro Today!',
  message:
    '<div><b>Get more with AQ Insight Pro:</b></div><div><ul><li>Dynamic filtering to focus on data most important to you</li><li>Quoting activity over time to see historical data on products added to projects & quotes</li><li>Industry trends & comparisons to see where your company stacks up to the competition</li><li>Export your data to generate reports on your company&apos;s performance</li></ul></div><p><div><b>NOTICE:</b> We will be removing Rep data from the main AQ Insight dashboard in an effort to refine your product&apos;s quoting activity. BUT we have introduced an exciting new feature for AQ Insight Pro that allows you to request specific Rep&apos;s data so that you have access to a Rep specific dashboard containing much more detail on who is quoting your products.</div><p><div><center><strong>Email <a href="mailto:sales@aq-fes.com">sales@aq-fes.com</a> to get started</strong></center></div>',
  read: false,
  startDate: repNotificationStartDate
};
const repDataRemovalNotificationFreeNotLogged = {
  key: 'repDataRemoval',
  title: 'Sign up for AQ Insight Pro Today!',
  message:
    '<div><b>Get more with AQ Insight Pro:</b></div><div><ul><li>Dynamic filtering to focus on data most important to you</li><li>Quoting activity over time to see historical data on products added to projects & quotes</li><li>Industry trends & comparisons to see where your company stacks up to the competition</li><li>Export your data to generate reports on your company&apos;s performance</li></ul></div><p><div><center><strong>Email <a href="mailto:sales@aq-fes.com">sales@aq-fes.com</a> to get started</strong></center></div>',
  read: false,
  startDate: repNotificationStartDate
};

const setNotifications = function handleSetNotifications(
  state: NotificationCenterState,
  notifications: Notification[]
): NotificationCenterState {
  return {
    ...state,
    notifications
  };
};

export const notificationStorageKey = 'readNotifications';

const getReadNotifications = function getReadNotifications(): string[] {
  const readNotifications = localStorage.getItem(notificationStorageKey);
  if (readNotifications) {
    return JSON.parse(readNotifications);
  }

  return [];
};

const getRepNotificationStartDate = function getRepNotificationStartDate():
  | string
  | null {
  return localStorage.getItem('repNotificationStartDate');
};

const getShowRepNotification = function getShowRepNotification(): boolean {
  return localStorage.getItem(showRepNotificationKey) === 'true';
};

const shouldIncludeRepNotification = function shouldIncludeRepNotification(
  notificationStartDate: Date
): boolean {
  const now = new Date(Date.now());
  if (isBefore(notificationStartDate, now)) {
    return true;
  }

  const overrideDate = getRepNotificationStartDate();
  if (!overrideDate) {
    return false;
  }
  const startDate = new Date(overrideDate);
  return isAfter(now, startDate);
};

const getNotificationsToShow = function getNotificationsToShow(
  license: LicenseType,
  shouldShowNotification: boolean
): Notification | null {
  if (license === LicenseType.Free && shouldShowNotification) {
    return repDataRemovalNotificationFree;
  }
  if (license === LicenseType.Free && !shouldShowNotification) {
    return repDataRemovalNotificationFreeNotLogged;
  }
  if (license === LicenseType.Pro && shouldShowNotification) {
    return repDataRemovalNotificationPro;
  }
  if (license === LicenseType.Pro && !shouldShowNotification) {
    return repDataRemovalNotificationProNotLogged;
  }

  return null;
};

const loadRepNotifications = function loadRepNotifications(
  repNotification: Notification | null
): Notification[] {
  if (
    !shouldIncludeRepNotification(repNotificationStartDate) ||
    !repNotification
  ) {
    return [];
  }

  const readNotifications = getReadNotifications();

  return [
    {
      ...repNotification,
      read: readNotifications.includes(repNotification.key)
    }
  ];
};

const notificationCenter = createModel({
  state: {
    open: false,
    expandedNotification: '',
    notifications: [] as Notification[]
  },
  reducers: {
    toggleOpen,
    markNotificationAsRead,
    expandNotification,
    setNotifications
  },
  effects: {
    loadNotifications(payload, rootState): void {
      const notificationInformation = getNotificationsToShow(
        rootState.license.type,
        getShowRepNotification()
      );

      const notifications = loadRepNotifications(notificationInformation);

      this.setNotifications(notifications);
    },
    saveReadNotification(readNotificationKey: string): void {
      const previouslyReadNotifications = getReadNotifications();
      if (previouslyReadNotifications.includes(readNotificationKey)) {
        return;
      }

      const readNotifications = JSON.stringify([
        readNotificationKey,
        ...previouslyReadNotifications
      ]);
      localStorage.setItem(notificationStorageKey, readNotifications);

      this.markNotificationAsRead(readNotificationKey);
    },
    setShowRepNotification(): void {
      const overrideDate = getRepNotificationStartDate();

      const showRepNotificationSet = getShowRepNotification();

      const startDate = overrideDate
        ? new Date(overrideDate)
        : repNotificationStartDate;

      const shouldSetRepNotification =
        isBefore(new Date(Date.now()), startDate) && !showRepNotificationSet;

      if (shouldSetRepNotification) {
        localStorage.setItem(showRepNotificationKey, 'true');
      }
    }
  }
});

export default notificationCenter;
