import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { InsightDispatch, iRootState } from '../../redux/store';
import { DashboardType } from '../../redux/models/dashboards';

const useStyles = makeStyles(theme => ({
  insightForReps: {
    color: '#fff',
    fontSize: theme.typography.fontSize,
    textTransform: 'none'
  }
}));

const LaunchInsightForReps = function LaunchInsightForReps(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();
  const dashboardType = useSelector(
    (state: iRootState) => state.dashboards.dashboardType
  );

  const launchInsightDashboardText =
    dashboardType === DashboardType.MfrRep
      ? 'Launch Insight Pro'
      : 'Launch Insight for Reps';

  const onLaunchInsightForRepsHandler = function HandleLaunchInsightForReps(): void {
    const newDashboardType =
      dashboardType === DashboardType.MfrRep
        ? DashboardType.Mfr
        : DashboardType.MfrRep;

    dispatch.filters.resetCompanies();
    dispatch.dashboards.updateDashboardType(newDashboardType);
  };

  const classes = useStyles();
  return (
    <Button
      className={classes.insightForReps}
      onClick={onLaunchInsightForRepsHandler}
    >
      {launchInsightDashboardText}
    </Button>
  );
};

export default LaunchInsightForReps;
