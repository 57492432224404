import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { InsightDispatch } from '../redux/store';

const CloseButton = function CloseButton(): React.ReactElement {
  const dispatch = useDispatch<InsightDispatch>();

  const handleClose = (): void => {
    dispatch.upgradeNotification.setVisible(false);
  };

  return (
    <Grid item xs={12} container direction='row' alignContent='flex-end'>
      <Grid item xs />
      <Grid item>
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={handleClose}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CloseButton;
