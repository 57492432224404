import React from 'react';
import { useSelector } from 'react-redux';
import FlyoutIcon from './Flyout/FlyoutIcon';
import ShareIcon from './Share/ShareIcon';
import NotificationIcon from './Notifications/NotificationIcon';
import { iRootState } from '../redux/store';
import { LicenseType } from '../redux/models/license';
import { useAuth0 } from '../Auth0Provider';
import { AQInsightRepAdmin, AQInsightMfr } from '../Constants';

const HeaderIcons = function HeaderIcons(): React.ReactElement {
  const { dataAccessLevel } = useAuth0();
  const licenseType = useSelector((state: iRootState) => state.license.type);

  const shouldShowIcon = function shouldShowIcon(): boolean {
    return (
      (licenseType === LicenseType.Pro && dataAccessLevel === AQInsightMfr) ||
      dataAccessLevel === AQInsightRepAdmin
    );
  };

  const showIcon = shouldShowIcon();

  return (
    <>
      {showIcon && <ShareIcon />}
      <NotificationIcon />
      <FlyoutIcon />
    </>
  );
};

export default HeaderIcons;
