import Axios from 'axios';

export const logoutSisense = async (): Promise<void> => {
  const sisenseInstance = Axios.create({
    baseURL: window.AutoQuotes.config.sisenseUrl
  });

  try {
    await sisenseInstance.get('api/v1/authentication/logout', {
      withCredentials: true
    });
  } catch {
    return Promise.resolve();
  }

  return Promise.resolve();
};

export default logoutSisense;
