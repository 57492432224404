import { makeStyles, Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ArrowForwardIos } from '@material-ui/icons';

import EndDateSelector from './EndDateSelector';
import StartDateSelector from './StartDateSelector';
import PresetDateRangeButton from './PresetDateRangeButton';

const useStyles = makeStyles(theme => ({
  popover: {
    backgroundColor: '#82a4c3',
    color: theme.palette.common.white,
    maxWidth: '250px'
  },
  arrow: {
    fontSize: 'inherit',
    marginRight: '5px'
  }
}));

const DateRangeSelectorPopover = function DateRangeSelectorPopover(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.popover}>
      <PresetDateRangeButton rangeSize={7} />
      <PresetDateRangeButton rangeSize={30} />
      <PresetDateRangeButton rangeSize={90} />
      <Grid container justify='space-between' alignItems='center'>
        <StartDateSelector />
        <Typography>to</Typography>
        <EndDateSelector />
        <ArrowForwardIos className={classes.arrow} />
      </Grid>
    </div>
  );
};

export default DateRangeSelectorPopover;
